<template>
	<div class="full-wrap">
		<div class="wrap question_types">
			<div class="tab_wrap" v-if="isEdit == 'false'">
				<div class="nav">
					<div class="item" :class="{active: tabNavCurrent === 0}" @click="tabNavCurrent = 0">控件</div>
					<span class="line"></span>
					<div class="item" :class="{active: tabNavCurrent === 1}" @click="tabNavCurrent = 1">大纲</div>
				</div>
				<div class="tab_list">
					<transition name="fade">
						<div class="tab_item widget" v-show="tabNavCurrent === 0">
							<draggable v-model="widgetList" v-bind="{group:{name: 'itxst',pull:'clone'},sort: false,}" chosenClass="d-chosen" filter=".drag-tips,.drag-site" :move="dragMove" @end="dragEnd">
								<transition-group class="list widget-list">
									<div class="item" v-for="item in widgetList" :key="item.id">
										<span class="txt">{{item.name}}</span>
									</div>
								</transition-group>
							</draggable>
						</div>
					</transition>
					<transition name="fade">
						<div class="tab_item catalogue" v-show="tabNavCurrent === 1">
							<ul class="list" v-if="list.length > 0">
								<li class="item" v-for="(item,index) in list" :key="'cata' + index">
									<a :class="{'active': o == currentWidget}" v-for="(o,i) in item.children" :key="'c' + i" :href="'#' + o.uuid" @click="setShow = true,currentWidget = o">{{o.label}}</a>
								</li>
							</ul>
							<noData v-else text="暂时还没有表单控件哦！"></noData>
						</div>
					</transition>
				</div>
			</div>
			<div class="main_area">
				<div class="edit_cont">
					<div class="edit_wrap">
						<div class="edit_inner" @click="setShow = false,currentWidget = ''" :style="{width: globalStyle.formWidth + 'px'}">
							<draggable v-model="list" class="parent-wrap" :class="{'is_dragging': isDragging}" animation="300" group="itxst" ghostClass="s-ghost" filter=".drag-tips,.drag-site" scroll="true">
								<transition-group class="parent-list">
									<template v-if="list.length > 0">
										<div class="parent-item" v-for="(item,index) in list" :key="'p' + index">
											<div v-if="isDragging" class="drag-site"></div>
											<draggable v-model="item.children" class="children-wrap" animation="300" group="itxst" ghostClass="s-ghost" filter=".drag-site" :move="childSortMove" @end="childSortEnd">
												<transition-group class="children-list" :class="[{'full': item.children && item.children.length > 0},{'empty': item.children && item.children.length == 0}]" :index="index">
													<div class="children-item" :class="['ybd-' + o.type,{'active': o == currentWidget && !isDragging}]" v-for="(o,i) in item.children" :key="'c' + i" @click.stop="setShow = true,currentWidget = o">
														<ybdInput v-if="o.type == 'input'" :detial="o" />
														<ybdTextarea v-else-if="o.type == 'textarea'" :detial="o" />
														<ybdRadio v-else-if="o.type == 'radio'" :detial="o" />
														<ybdCheckbox v-else-if="o.type == 'checkbox'" :detial="o" />
														<ybdSelect v-else-if="o.type == 'select'" :detial="o" />
														<ybdNumber v-else-if="o.type == 'number'" :detial="o" />
														<ybdDate v-else-if="o.type == 'date'" :detial="o" />
														<ybdLocation v-else-if="o.type == 'location'" :detial="o" />
														<ybdTable v-else-if="o.type == 'table'" :detial="o" />
														<ybdImage v-else-if="o.type == 'image'" :detial="o" />
														<ybdAttachment v-else-if="o.type == 'attachment'" :detial="o" />
														<ybdDivider v-else-if="o.type == 'divider'" :detial="o" />
														<div class="cover" v-show="!isDragging" :style="{height: 'calc( 100% - ' + o.itemStyle.interval + 'px )'}"></div>
														<div class="widget_operate" v-show="!isDragging">
															<div class="btn el-icon-document-copy" tip="复制" @click.stop="copyWidget(index,i)"></div>
															<div class="btn el-icon-delete" tip="删除" @click.stop="deleteWidget(index,i)"></div>
														</div>
													</div>
												</transition-group>
											</draggable>
											<div v-if="isDragging" class="drag-site"></div>
										</div>
									</template>
									<div v-else key="no-list" class="drag-tips" :class="{hide: !dragTipsFlag}">
										<div class="iconfont icon-yidong"></div>
										<div class="txt">拖拽控件至此处</div>
									</div>
								</transition-group>
							</draggable>
						</div>
					</div>
					<transition name="widthZoom">
						<div class="set_area" v-show="setShow">
							<div class="close_btn" @click="setShow = false">
									<i class="iconfont icon-shouqikuaijin"></i>
							</div>
							<div class="set_list">
								<!-- 控件设置 -->
								<widgetSet v-if="currentWidget && currentWidget != ''" :detial="currentWidget" />
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
		<div class="foot-btns">
			<el-button type="primary" @click="save">保存</el-button>
			<el-button @click="close">取消</el-button>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
import noData from "@/components/noData";
import ybdInput from "@/components/ybdInput";
import ybdTextarea from "@/components/ybdTextarea";
import ybdRadio from "@/components/ybdRadio";
import ybdCheckbox from "@/components/ybdCheckbox";
import ybdSelect from "@/components/ybdSelect";
import ybdNumber from "@/components/ybdNumber";
import ybdDate from "@/components/ybdDate";
import ybdLocation from "@/components/ybdLocation";
import ybdTable from "@/components/ybdTable";
import ybdImage from "@/components/ybdImage";
import ybdAttachment from "@/components/ybdAttachment";
import ybdDivider from "@/components/ybdDivider";
import widgetSet from "@/components/widgetSet";
import draggable from "vuedraggable";
import { get,post,postJson } from "/src/services/ajax.js";
export default {
	name: "questionTypes",
	components: {
		noData,
		draggable,
		ybdInput,
		ybdTextarea,
		ybdRadio,
		ybdCheckbox,
		ybdSelect,
		ybdNumber,
		ybdDate,
		ybdLocation,
		ybdTable,
		ybdImage,
		ybdAttachment,
		ybdDivider,
		widgetSet,
	},
	data() {
		return {
			identityType: "",
			formToken: sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			globalStyle: {
				themeColor: "#0cf",
				formWidth: 1100,
				contStyle:{
					paddingTop: 20,
					paddingHorizontal: 30,
					paddingBottom: 20,
				},
				itemStyle: {
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "#ddd",
					borderRadius: 0,
					backgroundColor: "#fff",
					paddingVertical: 10,
					paddingHorizontal: 10,
					interval: 15,
				},
				labelStyle: {
					width: 100,
					fontSize: 14,
					color: "#333",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					textAlign: "居左",
				},
				submitBtnStyle: {
					width: 100,
					height: 40,
					text: "提交",
					fontSize: 14,
					color: "#fff",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "",
					borderRadius: 4,
					backgroundColor: "",
					textAlign: "居中",
					paddingTop: 10,
					paddingHorizontal: 0,
					paddingBottom: 30,
				},
			},
			list: [],
			// 临时数据 用于页面展示 不需要提交
			widgetList: [
				{
					id: 1,
					type: "input",
					name: "单行文本",
					label: "单行文本",
					showLabel: true,
					required: true,
					placeholder: "请输入内容",
					value: "",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 2,
					type: "textarea",
					name: "多行文本",
					label: "多行文本",
					showLabel: true,
					required: true,
					placeholder: "请输入内容",
					value: "",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						rows: 3,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 3,
					type: "radio",
					name: "单选框组",
					label: "单选框",
					showLabel: true,
					required: true,
					value: "",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					options: [
						{
							value: "选项一",
						},
						{
							value: "选项二",
						},
						{
							value: "选项三",
						},
					],
					inputStyle: {
						orientationMode: "横向",
						intervalVertical: 12,
						intervalHorizontal: 30,
					},
				},
				{
					id: 4,
					type: "checkbox",
					name: "复选框组",
					label: "复选框",
					showLabel: true,
					required: true,
					value: [],
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					options: [
						{
							value: "选项一",
						},
						{
							value: "选项二",
						},
						{
							value: "选项三",
						},
					],
					inputStyle: {
						orientationMode: "横向",
						intervalVertical: 12,
						intervalHorizontal: 30,
					},
				},
				{
					id: 5,
					type: "select",
					name: "下拉列表",
					label: "下拉列表",
					showLabel: true,
					required: true,
					value: "",
					placeholder: "请选择",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					options: [
						{
							value: "选项一",
						},
						{
							value: "选项二",
						},
						{
							value: "选项三",
						},
					],
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 6,
					type: "number",
					name: "数字输入",
					label: "数字",
					showLabel: true,
					required: true,
					placeholder: "请输入数字",
					value: "",
					allowDecimal: false,
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 7,
					type: "date",
					name: "日期时间",
					label: "日期时间",
					showLabel: true,
					required: true,
					placeholder: "请选择日期时间",
					startPlaceholder: "开始日期时间",
					endPlaceholder: "结束日期时间",
					value: "",
					isRang: false,
					dataType: "datetime",
					rangDataType: "datetimerange",
					queryItemVal: false,
					columnItemVal: false,
					isCurrent: false,
					valueFormat: "文字",
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						iconSite: "居左",
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 8,
					type: "location",
					name: "省市联动",
					label: "省市联动",
					showLabel: true,
					required: true,
					placeholder: "请选择地区",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 9,
					type: "table",
					name: "表格输入",
					label: "表格",
					showLabel: true,
					rowNum: 5,
					cols: [
						{ value: "字段1" },
						{ value: "字段2" },
						{ value: "字段3" },
						{ value: "字段4" },
						{ value: "字段5" },
					],
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						fontSize: 14,
						color: "#999",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						textAlign: "居中",
						backgroundColor: "#fff",
						borderColor: "#EBEEF5",
					},
				},
				{
					id: 10,
					type: "image",
					name: "图片上传",
					label: "图片上传",
					showLabel: true,
					required: true,
					allowNum: 1,
					allowSize: 2,
					imgList: [],
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
							size: 140,
							text: "上传图片",
							fontSize: 14,
							color: "#999",
							fontWight: "normal",
							fontStyle: "normal",
							textDecoration: "none",
							borderWidth: 1,
							borderStyle: "dashed",
							borderColor: "#ddd",
							borderRadius: 4,
							backgroundColor: "#fff",
					},
				},
				{
					id: 11,
					type: "attachment",
					name: "附件上传",
					label: "附件上传",
					showLabel: true,
					required: true,
					allowNum: 1,
					allowSize: 2,
					fileList: [],
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						width: 120,
						height: 40,
						isIcon: true,
						text: "点击上传",
						fontSize: 14,
						color: "#fff",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "",
						borderRadius: 4,
						backgroundColor: "",
					},
				},
				{
					id: 12,
					type: "divider",
					name: "分割线",
					label: "分割线",
					showText: false,
					dividerText: "",
					fontSize: 14,
					color: "#999",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					dividerTextPosition: "居中",
					size: 1,
					dividerColor: "#DCDFE6",
					marginVertical: 24,
					marginHorizontal: 0,
				},
			],
			tabNavCurrent: 0,
			setShow: false,
			headerImgTypeList: [],
			headerImgTypeId: "",
			headerImgList: [],
			formTitleTips: false,
			formDescriptTips: false,
			isDragging: false,
			currentWidget: {},
			dragTipsFlag: true,
			submitBtnFoldFlag: false,
			contFoldFlag: false,
			itemFoldFlag: false,
			labelFoldFlag: false,
			submitBtnPaddingFlag: "上",
			contPaddingFlag: "上",
			itemPaddingFlag: "上下",
			formHeadTextPaddingFlag: "上下",
			formTitlePaddingFlag: "上",
			formDescriptPaddingFlag: "上下",
			headerImageListShowFlag: false,
			mFormHeadFoldFlag: false,
			mFormHeadTextPaddingFlag: "上下",
			mFormTitleFoldFlag: false,
			mFormTitlePaddingFlag: "上",
			mFormDescriptFoldFlag: false,
			mFormDescriptPaddingFlag: "上下",
			mContFoldFlag: false,
			mContPaddingFlag: "上",
			mItemFoldFlag: false,
			mItemPaddingFlag: "上下",
			mLabelFoldFlag: false,
			mInputFoldFlag: false,
			mSubmitBtnFoldFlag: false,
			mSubmitBtnPaddingFlag: "上",
			id: '',
			isEdit: 'false',
			type_id: ''
		};
	},
	watch: {
		"globalStyle.itemStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.globalStyle.itemStyle;
					this.list.forEach((o) => {
						o.children.forEach((m) => {
							m.itemStyle = {...styleStr};
						});
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.labelStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.globalStyle.labelStyle;
					this.list.forEach((o) => {
						o.children.forEach((m) => {
							m.labelStyle = {...styleStr};
						});
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.themeColor": {
			handler() {
				document.documentElement.style.setProperty("--theme-custom-color",this.globalStyle.themeColor);
			},
		},
		"globalStyle.contStyle": {
			handler() {
				const styleObj = this.globalStyle.contStyle;
				$(".parent-wrap").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
				});
			},
			deep: true,
			immediate: true,
		}
	},
	created(){
		const {id,isEdit,authToken,identityType,type_id} = this.$route.query;
		this.id = id;
		this.type_id = type_id;
		this.isEdit = isEdit;
		this.identityType = identityType?identityType:sessionStorage.getItem('identityType');
		if(!this.formToken){
			// 校验登录状态
			if(authToken && identityType){
				this.auth(authToken,identityType);
				sessionStorage.setItem('identityType',identityType);
			}else{
				console.log('去登录');
			}
		}else{
			if(isEdit != 'false'){
				this.getData();
			}
		}
	},
	methods: {
		// 登录
		auth(authToken,identityType){
			const _this = this;
			post('/formdesign/auth.api',{authToken,identityType}).then(res => {
				if(res.code == 200){
					sessionStorage.setItem('token',res.data.form_token);
					_this.formToken = res.data.form_token;
					location.reload();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取控件数据
		getData(){
			const _this = this;
			get('/formdesign/getFormQuestionManage.api',{id: _this.id}).then(res => {
				if(res.code == 200){
					if(res.data.formComponents){
						_this.list = [{children: [{...res.data.formComponents,_id: res.data._id}]}];
						_this.wId = res.data._id;
					}
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},		
		// 从左侧拖拽出 过程
		dragMove(e) {
			if(e.to._prevClass == "list widget-list" || e.to._prevClass == "children-list full") return false;
			this.dragTipsFlag = false;
			this.isDragging = true;
		},
		// 从左侧拖拽出 结束
		dragEnd(e) {
			this.isDragging = false;
			let item = {
				children: [
					JSON.parse(
						JSON.stringify({
							...this.widgetList[e.oldDraggableIndex],
							itemStyle: this.globalStyle.itemStyle,
							labelStyle: this.globalStyle.labelStyle,
							uuid: this.getUuid(),
						})
					),
				],
			};
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
			} else if (e.to._prevClass == "children-list full") {
				this.list[$(e.to).attr("index")].children.splice(e.newDraggableIndex, 1, item.children[0]);
			}
			if (this.list.length == 0) {
				this.dragTipsFlag = true;
			}
		},
		// 右侧拖拽 过程
		childSortMove(e) {
			this.isDragging = true;
			if (e.to._prevClass == "list widget-list" || (e.to._prevClass == "children-list full" && e.to != e.from)) return false;
		},
		// 右侧拖拽完成
		childSortEnd(e) {
			this.isDragging = false;
			let item = {
				children: [e.item._underlying_vm_],
			};
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
				this.list.forEach((o, i) => {
					if (o.children.length == 0) {
						this.list.splice(i, 1);
					}
				});
			}
		},
		// 控件生成唯一标识
		getUuid() {
			let s = [];
			let hexDigits = "0123456789abcdef";
			for (let i = 0; i < 36; i++) {
				s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
			}
			s[14] = "4";
			s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
			s[8] = s[13] = s[18] = s[23] = "-";
			return s.join("");
		},
		// 复制控件
		copyWidget(index, i) {
			let item = {
				children: [
					JSON.parse(
						JSON.stringify({
							...this.list[index].children[i],
							uuid: this.getUuid(),
						})
					),
				],
			};
			this.list.splice(index + 1, 0, item);
		},
		// 删除控件
		deleteWidget(index, i) {
			if (this.list[index].children.length == 1) {
				this.list.splice(index, 1);
			} else {
				this.list[index].children.splice(i, 1);
			}
		},
		save(){
			const _this = this;
			if(_this.list.length > 0){
				let jsonData = {};
				if(_this.isEdit != 'false'){ //编辑
					jsonData = {
						type_id: _this.type_id,
						list: [..._this.list]
					};
					postJson('/formdesign/updateFormQuestionManage.api',jsonData).then(res => {
						if(res.code == 200){
							_this.$message({
								type: 'success',
								message: '保存成功！',
								duration: 500,
								onClose(){
									_this.close();
								}
							})
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}else{	//新增
					jsonData = {
						type_id: _this.type_id,
						list: [..._this.list]
					};
					postJson('/formdesign/saveFormQuestionManage.api',jsonData).then(res => {
						if(res.code == 200){
							_this.$message({
								type: 'success',
								message: '保存成功！',
								duration: 500,
								onClose(){
									_this.close();
								}
							})
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			}else{
				_this.$message.error('请拖入控件！');
			}
		},
		close(){
			window.location.href="about:blank";
			window.close();
		}
	},
};
</script>
<style lang="less" scoped>
.full-wrap{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	.question_types {
		flex: 1;
		display: flex;
		width: 100%;
		overflow: hidden;
		.tab_wrap {
			display: flex;
			flex-direction: column;
			width: 290px;
			height: 100%;
			border-right: 1px solid #ddd;
			background-color: #f5f5f5;
			position: relative;
			.nav {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ddd;
				.item {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 50px;
					font-size: 15px;
					cursor: pointer;
				}
				.line {
					width: 1px;
					height: 30px;
					background-color: #ddd;
				}
				.active {
					background-color: #fff;
					color: var(--theme-color);
				}
			}
			.tab_list {
				flex: 1;
				overflow: hidden;
				.tab_item {
					height: 100%;
					padding: 20px 30px 10px;
					overflow-y: auto;
				}
				.widget {
					.title {
						margin: 0 0 10px;
						font-weight: bold;
					}
					.list {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						.item {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 48%;
							height: 36px;
							border: 1px solid #ddd;
							margin: 0 0 10px;
							border-radius: 2px;
							background-color: #fff;
							font-size: 12px;
							cursor: pointer;
							user-select: none;
							&:hover{
								box-shadow: 0 0 10px rgba(0,0,0,.1);
							}
						}
					}
				}
				.catalogue {
					.list {
						.item {
							display: flex;
							margin: 0 0 10px;
							a {
								flex: 1;
								height: 34px;
								line-height: 34px;
								border: 1px solid #ddd;
								border-radius: 3px;
								background-color: #fff;
								text-align: center;
								padding: 0 10px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 12px;
								color: #666;
								&:hover {
										color: var(--theme-color);
								}
							}
							a + a {
								border-left: 1px solid #ddd;
								margin: 0 0 0 10px;
							}
							a.active {
								border-color: var(--theme-color);
								background-color: var(--theme-color);
								color: #fff;
							}
						}
					}
				}
			}
		}
		.main_area {
			flex: 1;
			overflow: hidden;
			.edit_cont {
				display: flex;
				height: 100%;
				.edit_wrap{
					flex: 1;
					padding: 20px;
					position: relative;
					overflow-x: auto;
					.edit_inner {
						display: flex;
						flex-direction: column;
						width: 1100px;
						height: 100%;
						padding: 30px 0 0;
						margin: 0 auto;
						background-color: #fff;
						overflow-y: auto;
						overflow-x: hidden;
						.parent-wrap {
							flex: 1;
							padding: 20px 30px;
							.parent-list {
								display: block;
								width: 100%;
								height: 100%;
								.parent-item {
									.children-wrap {
										.children-list {
											display: flex;
											width: 100%;
											.children-item {
												flex: 1;
												position: relative;
												overflow: hidden;
												.cover {
													display: none;
													width: 100%;
													height: 100%;
													position: absolute;
													top: 0;
													left: 0;
													border: 1px solid #fff;
													z-index: 99;
													&:after {
														content: "";
														width: 100%;
														height: 100%;
														position: absolute;
														top: 0;
														left: 0;
														border: 1px dashed var(--theme-color);
														cursor: move;
														box-sizing: border-box;
													}
												}
												&:hover {
													.cover {
														display: block;
													}
													.widget_operate {
														display: flex;
													}
													overflow: unset;
												}
												.widget_operate {
													display: none;
													position: absolute;
													top: -15px;
													right: 10px;
													z-index: 999;
													.btn {
														display: flex;
														align-items: center;
														justify-content: center;
														width: 24px;
														height: 24px;
														border: 1px solid var(--theme-color);
														margin: 0 10px;
														color: var(--theme-color);
														border-radius: 50%;
														background-color: #fff;
														cursor: pointer;
														position: relative;
														&:hover {
															background-color: var(--theme-color);
															color: #fff;
															&:after {
																visibility: visible;
																opacity: 1;
																top: -25px;
																transition: 0.2s;
															}
														}
														&:after {
															content: attr(tip);
															visibility: hidden;
															opacity: 0;
															padding: 4px 8px;
															position: absolute;
															top: -35px;
															white-space: nowrap;
															font-size: 12px;
															background-color: #333;
															color: #fff;
															border-radius: 3px;
															transition: 0.2s;
														}
													}
													.el-icon-delete {
														border-color: var(--theme-orange-color);
														color: var(--theme-orange-color);
														&:hover {
															background-color: var(--theme-orange-color);
															color: #fff;
														}
													}
												}
											}
											.children-item + .children-item .item {
												border-left: none !important;
											}
											.children-item.active {
												overflow: unset;
												.widget_operate {
													display: flex;
												}
												.cover {
													display: block;
													&:after {
														background-color: rgba(0, 204, 255, 0.1);
													}
												}
											}
										}
									}
									.drag-site {
										height: 10px;
									}
								}
								.drag-tips {
									display: flex;
									align-items: center;
									justify-content: center;
									height: 100%;
									max-height: 300px;
									min-height: 200px;
									border: 1px dashed var(--theme-color);
									background-color: #f5f7f9;
									.iconfont {
										font-size: 20px;
										font-weight: bold;
										color: var(--theme-color);
									}
									.txt {
										margin: 0 0 0 10px;
										font-size: 16px;
										color: #666;
									}
								}
								.drag-tips.hide {
									display: none;
								}
								.d-chosen,
								.s-ghost {
									flex: 1 !important;
									min-height: 50px;
									position: relative !important;
									font-size: 0 !important;
									&::after {
										content: "放到这里";
										display: flex;
										align-items: center;
										justify-content: center;
										width: 100%;
										height: 100%;
										position: absolute;
										top: 0;
										left: 0;
										border: 1px dashed #f80;
										color: #f80;
										font-size: 15px;
										background-color: #fff4e8;
										box-sizing: border-box;
									}
								}
							}
						}
						.parent-wrap.is_dragging {
							.children-item:not(.s-ghost) {
								&:after {
									content: "";
									display: block;
									width: 100%;
									height: 100%;
									background-color: rgba(0, 0, 0, 0.2);
									position: absolute;
									top: 0;
									left: 0;
									z-index: 1;
								}
							}
						}
					}
				}
				.set_area {
					width: 330px;
					border-left: 1px solid #e5e5e5;
					background-color: #fff;
					position: relative;
					z-index: 999;
					.close_btn {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 56px;
						border: 1px solid #e5e5e5;
						border-right: none;
						margin: -28px 0 0;
						border-radius: 5px 0 0 5px;
						position: absolute;
						top: 50%;
						left: -23px;
						background-color: #fff;
						box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);
						cursor: pointer;
						z-index: 9;
						.iconfont {
							font-size: 14px;
							color: #999;
							&:hover {
								color: var(--theme-color);
							}
						}
					}
					.set_list {
						height: 100%;
						user-select: none;
						.set_item {
							display: flex;
							flex-direction: column;
							width: 330px;
							height: 100%;
							.title {
								display: flex;
								align-items: center;
								height: 58px;
								padding: 0 24px;
								border-bottom: 1px solid #e5e5e5;
								font-size: 16px;
								white-space: nowrap;
							}
							.list {
								flex: 1;
								overflow-y: auto;
								padding: 0 10px 10px;
								.item {
									padding: 0 5px;
									border-bottom: 1px solid #eee;
									.switch_btn {
										display: flex;
										align-items: center;
										justify-content: space-between;
										height: 50px;
										.label {
											display: flex;
											align-items: center;
											.explain {
												position: relative;
												.el-icon-info {
													display: block;
													margin: 0 0 0 5px;
													color: #aaa;
													cursor: pointer;
													font-size: 18px;
												}
												.cont {
													visibility: hidden;
													opacity: 0;
													background-color: #fff;
													box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
													position: fixed;
													margin: -37px 0 0 -410px;
													z-index: 99;
													transition: all 0.3s;
													.angle {
														width: 10px;
														height: 10px;
														transform: rotate(45deg);
														background: #fff;
														box-shadow: 3px -3px 5px rgba(0, 0, 0, 0.1);
														position: absolute;
														right: -6px;
														top: 20px;
													}
													.tips-main {
														display: flex;
														align-items: center;
														width: 390px;
														padding: 20px 18px;
														.imgbox {
															img {
																display: block;
																width: 200px;
															}
														}
														.infobox {
															margin: 0 0 0 10px;
															.tips-head {
																line-height: 21px;
																color: #707070;
																padding-bottom: 10px;
															}
															.tips-txt {
																font-size: 12px;
																line-height: 18px;
																color: #333;
																.p1 {
																	color: #999;
																}
															}
														}
													}
												}
												&:hover {
													.cont {
														visibility: visible;
														opacity: 1;
														margin-left: -395px;
													}
													.el-icon-info {
														color: var(--theme-color);
													}
												}
											}
										}
										.fold_btn {
											display: flex;
											align-items: center;
											font-size: 13px;
											color: #999;
											cursor: pointer;
											.iconfont {
												font-size: 14px;
												margin: 0 0 0 3px;
												transition: all 0.2s;
											}
											&:hover {
												color: var(--theme-color);
											}
										}
										.fold_btn.active {
											.iconfont {
												margin-top: -3px;
												transform: rotate(-180deg);
												transition: all 0.2s;
											}
										}
									}
									.sub_list {
										padding: 0 10px;
										margin: 0 0 10px;
										background-color: #f5f5f5;
										overflow: hidden;
										.sub_item {
											display: flex;
											justify-content: space-between;
											padding: 10px 0;
											border-top: 1px dashed #ddd;
											.sub_label {
												line-height: 28px;
												color: #888;
												font-size: 13px;
											}
											&:first-child {
												border: none;
											}
										}
									}
									.el-input__inner {
										width: 200px;
										height: 28px;
										font-size: 13px;
										padding: 0 5px;
									}
									.el-input__icon {
										line-height: 28px;
									}
									.el-color-picker {
										display: block;
										margin: 0 0 0 5px;
										border-radius: 2px;
										background-color: #fff;
									}
									.el-textarea {
										width: 200px;
										.el-textarea__inner {
											padding: 5px;
											font-family: inherit;
											font-size: 13px;
										}
									}
									.avatar-uploader {
										.el-upload {
											display: flex;
											align-items: center;
											justify-content: center;
											width: 70px;
											height: 70px;
											border: 1px dashed #d9d9d9;
											border-radius: 3px;
											cursor: pointer;
											.avatar{
												width: 100%;
												height: 100%;
												position: relative;
												img{
													width: 100%;
													height: 100%;
													object-fit: contain;
												}
												.el-icon-error{
													font-size: 20px;
													background-color: #fff;
													border-radius: 50%;
													color: #333;
													position: absolute;
													top: -8px;
													right: -8px;
													z-index: 9;
												}
											}
											.avatar-uploader-icon {
												font-size: 28px;
												color: #8c939d;
											}
											&:hover {
												border-color: var(--theme-color);
											}
										}
									}
									.text_style {
										display: flex;
										.iconfont {
											line-height: 28px;
											margin: 0 0 0 5px;
											color: #888;
											cursor: pointer;
										}
										.iconfont.active {
											color: var(--theme-color);
										}
										.el-input__inner {
											width: 104px !important;
										}
									}
									.silder {
										width: 200px;
										padding: 0px 8px 0 0;
										.el-slider__runway {
											margin: 11px 0;
											.el-slider__bar {
												background-color: var(--theme-color);
											}
											.el-slider__button {
												border-color: var(--theme-color);
											}
										}
									}
									.border_style {
										display: flex;
										.el-input__inner {
												width: 60px;
										}
										.el-select {
											.el-input__inner {
												width: 102px;
												margin: 0 0 0 5px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.foot-btns{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
		border-top: 1px solid #ddd;
		background-color: #fff;
		.el-button{
			padding: 10px 25px;
		}
	}
}
</style>